import { Component, ContentChild, Input, forwardRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { AplsInputFileService, AplsInput, AplsModal, AplsInputForm } from '@apollus-ngx/orfeu';

import { AplsUpload } from '@apollusepi/common/servicos';

export const APLS_FILE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileVisualizacaoDescritivoComponent),
  multi: true
};

/**
 * Esse component não possui a opção desabilitado.
 */
@Component({
  selector: 'apls-file-visualizacao-descritivo',
  templateUrl: './file-visualizacao-descritivo.component.html',
  styleUrls: ['./file-visualizacao-descritivo.component.scss'],
  providers: [APLS_FILE_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileVisualizacaoDescritivoComponent implements ControlValueAccessor {
  @Input() label = '';

  // Recupera o input dentro do ng-content
  @ContentChild(AplsInput) input: AplsInput;

  // Recupera component de input-form para atualizar a possição dos itens internos.
  @ViewChild(AplsInputForm) mostrador: AplsInputForm;

  disabled = false;

  /**
   * Registra se tem arquivo selecionado.
   *
   * Essa propriedade é importante pro template.
   */
  hasFile = false;

  enviando = false;

  dialogoArquivo;

  // Guarda o nome que vem da api.
  nomeArquivo;

  imagemPadrao = './assets/images/imagem-padrao.png';

  // Guarda a url do arquivo.
  imagem = this.imagemPadrao;

  width = null;

  _onTouched = () => {};

  private _cvaOnChange: (value: any) => void = () => {};

  private _validatorOnChange: (value: any) => void = () => {};

  constructor(private aplsUpload: AplsUpload, private aplsModal: AplsModal, private changeDetectorRef: ChangeDetectorRef) {
    this.dialogoArquivo = new AplsInputFileService('.png, .jpg, .jpeg');
  }

  cancel() {
    if (!this.disabled) {
      this.hasFile = false;
      this.enviando = false;
      this.imagem = this.imagemPadrao;
      this.dialogoArquivo = new AplsInputFileService('.png, .jpg, .jpeg');

      this.input.control.reset(null);
      this._cvaOnChange(null);
    }
  }

  btnOpenDialog() {
    if (!this.disabled) {
      if (!this.enviando) {
        this.input.control.control.setErrors({ upload_file: true });
        this.dialogoArquivo.open(file => {
          this.enviando = true;
          this.changeDetectorRef.markForCheck();
          this.aplsUpload
            .exec(file, '.png, .jpg, .jpeg')
            .then((nome: string) => {
              this.finalizarUpload(nome);
            })
            .catch(() => {
              this.finalizarUpload();
            });
        });
      }
    }
  }

  finalizarUpload(nome?: string) {
    this.input.control.control.setErrors(null);
    this.enviando = false;

    if (nome) {
      this.nomeArquivo = nome;
      this._cvaOnChange(this.nomeArquivo);
      this.imagem = this.aplsUpload.retornaURL(nome);
      this.hasFile = true;
    } else {
      this.hasFile = false;
    }

    this.trataMaxLenght();
    this.changeDetectorRef.markForCheck();
  }

  trataMaxLenght() {
    setTimeout(() => this.mostrador.setaPosicoes(), 200);
  }

  btnOpenFile() {
    if (!!this.nomeArquivo && this.nomeArquivo != this.imagemPadrao) {
      this.aplsModal.fotos([this.aplsUpload.retornaURL(this.nomeArquivo)]);
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this._validatorOnChange = fn;
  }

  writeValue(value: any): void {
    this.nomeArquivo = value;
    if (!!value) {
      this.hasFile = true;
      this.imagem = this.aplsUpload.retornaURL(value);
    } else {
      this.hasFile = false;
    }
    this.changeDetectorRef.markForCheck();
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  registerOnChange(fn: (value: any) => void): void {
    this._cvaOnChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
