import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'apls-mensagem-alerta',
  templateUrl: './mensagem-alerta.component.html',
  styleUrls: ['./mensagem-alerta.component.scss'],
  host: {
    class: 'alert alert-warning',
    "role": "alert"
  }
})
export class MensagemAlertaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
