import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { AplsPopupComponent } from '@apollusepi/common/componentes/popup/popup.component';
import { PopupRefComponent } from '@apollusepi/common/componentes/popup/popup-ref/popup-ref.component';
import { PopupContentComponent } from '@apollusepi/common/componentes/popup/popup-content/popup-content.component';

const DECLARATIONS_EXPORTS = [PopupRefComponent, AplsPopupComponent, PopupContentComponent];

@NgModule({
  imports: [CommonModule, OverlayModule],
  declarations: [...DECLARATIONS_EXPORTS],
  exports: [...DECLARATIONS_EXPORTS]
})
export class AplsPopupModule {}
