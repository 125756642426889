import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';

import { UsuarioLogado } from '@apollus-ngx/core';
import { environment } from '@apollusepi/environments';

@Injectable()
export class LogadoGuard implements CanActivate {
  aplicacao = document.location.origin + (!environment.production ? '/' : '/apollus');

  constructor(protected aplsUsuarioLogado: UsuarioLogado, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.aplsUsuarioLogado.token) {
      this.router.navigate(['/'], {});
      return false;
    } else {
      return true;
    }
  }
}
