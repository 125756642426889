/**********************************************************
 * Criador: Thiago Feijó                                  *
 * Data: 14/12/2017                                       *
 * Descrição: envolviment padrão.                         *
 * *******************************************************/

export const environment = {
  production: false,
  analytics: null,
  base: '/',
  endpoint: 'http://hgmed2.com.br/documentos-server/',
  upload: 'http://hgmed2.com.br/documentos-server/upload/',
  download: 'http://hgmed2.com.br/documentos-server/arquivos/',
  getLanguage: 'pt-BR',
  // endpoint: 'http://localhost/hgmed-server/',
  // upload: 'http://localhost/hgmed-server/upload/',
  // download: 'http://localhost/hgmed-server/arquivos/',
  relatorio: '',
  pasta_tmp: ''
};