import { Directive, HostListener, Optional, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[InputNumerico]'
})
export class InputNumericoDirective {

  /**
   * determina uma regex diferente da padrao.
   * 
   * ***Exemplo HTML:***
    ```html
     <input aplsInput InputNumerico  [permitePonto]="false" [regex]="'/\D/g'" /> 
    ```
   * 
   */
  @Input('regex')
  regex:any = /[^0-9\.]+/g;
  
  /**
   * define a possibilidade de permitir digitacao do PONTO.
   * Por padrao é TRUE | permite ponto
   * 
   * ***Exemplo HTML:***
    ```html
     <input aplsInput InputNumerico  [permitePonto]="false" [regex]="'/\D/g'" /> 
    ```
   * 
   */
  @Input('permitePonto')
  permitePonto:boolean = true;

  constructor(@Optional() private control: NgControl) { }
  
  /**
   * Pega a tecla no keydown.
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if(!this.permitePonto && e.keyCode == 190){
      e.preventDefault();
    }

    if (
      [46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
      // Permite: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Permite: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Permite: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Permite: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Permite: aplicacao, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // Deixe acontecer, não faça nada. Trata no keyup caso tenha forçado a entrada de uma letra.
      return;
    }

    // Certifique-se de que é um número e pare a tecla
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  /**
   * Checa no keyup para validar o se o dado no campo está valido.
   */
  @HostListener('keyup', ['$event'])
  onKeyUp($event: any) {
    let text = $event.target.value;
    // text = text.replace(/[^0-9\.]+/g, '');
    text = text.replace(this.regex, '');
    $event.target.value = text;

    if (!!this.control && this.control.control) {
      this.control.control.setValue(text);
    }
  }

  /**
   * Checa no keyup para validar o se o dado no campo está valido.
   */
  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    let text = $event.target.value;
    //text = text.replace(/[^0-9\.]+/g, '');
    text = text.replace(this.regex, '');
    $event.target.value = text;

    if (!!this.control && this.control.control) {
      this.control.control.setValue(text);
    }
  } 
}
