import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverlayModule} from '@angular/cdk/overlay';

import {AplsOptionModule} from '../core/option';
import {AplsPseudoCheckboxModule} from '../core/selection';
import {AplsTooltipModule} from '../tooltip';
import {APLS_SELECT_SCROLL_STRATEGY_PROVIDER, AplsSelect, AplsSelectTrigger} from './select';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    AplsOptionModule,
    AplsTooltipModule,
    AplsPseudoCheckboxModule,
  ],
  exports: [AplsSelect, AplsSelectTrigger, AplsOptionModule],
  declarations: [AplsSelect, AplsSelectTrigger],
  providers: [APLS_SELECT_SCROLL_STRATEGY_PROVIDER]
})
export class AplsSelectModule {}
