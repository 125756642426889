import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'apls-popup-content',
  exportAs: 'aplsPopupContent',
  templateUrl: './popup-content.component.html',
  styleUrls: ['./popup-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
