/**
 * Copyright Apollus EHS Solution Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AplsInputModule, AplsInputFileModule, AplsModalModule } from '@apollus-ngx/orfeu';

import { AplsPreloaderModule } from '@apollusepi/common/componentes/preloader';
import { FileVisualizacaoDescritivoComponent } from '@apollusepi/common/componentes/file-visualizacao-descritivo/file-visualizacao-descritivo.component';

const EXPORTED_DECLARATIONS = [FileVisualizacaoDescritivoComponent];

@NgModule({
  imports: [CommonModule, AplsInputModule, AplsInputFileModule, AplsModalModule, AplsPreloaderModule],
  declarations: EXPORTED_DECLARATIONS,
  exports: EXPORTED_DECLARATIONS
})
export class AplsFileVisualizacaoDescritivoModule {}
