export const MENSAGENS_PT_BR = {
  sem_acesso_funcao: 'Você não possui permissão para acessar esta funcionalidade.',
  salvo_com_sucesso: 'salvo com sucesso',
  registro_excluido_com_sucesso: 'Registro excluído com sucesso',
  excluido_com_sucesso: 'excluído com sucesso',
  msg_deseja_salvar_alteracoes: 'Você deseja salvar as alterações realizadas?',
  msg_form_cadastro_andamento: 'Para prosseguir é necessário Salvar/Cancelar a edição/criação em andamento.',
  msg_registro_desatualizado: 'Os dados foram atualizados por outro usuário. Favor visualizar novamente o registro.',
  msg_form_invalido: 'Existe um ou mais campos obrigatórios que não foram preenchidos.',
  msg_erro_requisicao: 'Erro ao precessar requisição.',
  msg_erro_interceptor: 'Ocorreu um erro inesperado. Favor entrar em contato o suporte.',
  msg_erro_ghe_inserido: 'Existe um ou mais GHEs que já foram adicionados na lista. Favor verificar.',
  msg_erro_atividadecritica_inserido: 'Existe uma ou mais Atividades Críticas que já foram adicionadas na lista. Favor verificar.',
  msg_erro_cargo_inserido: 'Este cargo já foi adicionado na lista. Favor verificar.',
  msg_erro_funcionario_inserido: 'Este funcionário já foi adicionado na lista. Favor verificar.',
  msg_sucesso_associacao_ghe: 'Associação de GHEs duplicada com sucesso. Para que a ação seja permanentemente salva, clique no botão SALVAR.',
  msg_sucesso_duplicar: 'Cadastro duplicado com sucesso.',
  msg_erro_sem_ghe_associar: 'Não existem GHEs para associar. Favor verificar.',
  msg_ghe_excluido: 'GHE excluído com sucesso. Para que a alteração seja permanentemente salva, clique no botão SALVAR.',
  msg_funcionario_excluido: 'Funcionário excluído com sucesso. Para que a alteração seja permanentemente salva, clique no botão SALVAR.',
  msg_cargo_excluido: 'Cargo excluído com sucesso. Para que a alteração seja permanentemente salva, clique no botão SALVAR.',
  msg_atividade_excluido: 'Atividade Crítica excluída com sucesso. Para que a alteração seja permanentemente salva, clique no botão SALVAR.’',
  msg_risco_excluido: 'Risco excluído com sucesso. Para que a alteração seja permanentemente salva, clique no botão SALVAR.’',
  msg_configuracao_salva: 'Configurações salvas com sucesso.',
  msg_riscos_duplicados: 'Risco(s) já adicionado(s) na lista. Favor verificar.',
  msg_ca_nao_preenchido: "O campo 'CA' não foi preenchido. Deseja prosseguir mesmo assim?",
  atencao: 'Atenção!',
  msg_categoria_descricao_unique: 'Já existe uma categoria com essa descrição cadastrada no sistema. Favor verificar.',
  msg_exclusao_registro_vinculo: 'Este registro possui vínculo com outros módulos do sistema e não pode ser excluído.',    
  apollus_erro_requisicao: 'Ocorreu um erro durante a requisição',  
  lista_de: 'Lista de',
  cadastro_de: 'Cadastro de',
  categoria: 'Categoria',
  lb_categoria: 'categoria',
  descricao: 'descricao',
  status: 'status',
  revisor: 'Revisor',
  data: 'Data',
  deseja_realmente_excluir: 'Deseja realmente excluir esse item?',
  cancelar: 'Cancelar',
  excluir: 'Excluir',
  existe_informacoes_nao_foram_salvas: 'Existem informações que não foram salvas. Deseja salvá-las antes de sair?',
  sair: 'Sair',
  salvar_sair: 'Salvar e Sair',
  tecnica_medicao: 'Técnica de Medição',
  msg_campo_categoria: 'Pelo menos um categoria deve ser selecionada',
  msg_tecnica_medicao_descricao_unique:'Atenção! Já existe uma Técnica de Medição com a mesma descricrição. Favor verificar.',
  msg_duplicado_sucesso:'Cadastro duplicado com sucesso.',
  periculoso: 'Periculoso',
  cargo: 'Cargo',
  recomendacao: 'Recomendação',
  msg_recomendacao_descricao_unique: 'Atenção! Já existe uma Recomendação com a mesma descrição. Favor verificar.',
  dano_saude: 'Danos à Saúde',
  menu_cadastro: 'Menu Cadastro',
  cadastro_geral: 'Cadastro Geral',
  msg_dano_saude_descricao_unique: 'Atenção! Já existe um Dano à Saúde com a mesma descrição. Favor verificar.',
  quimico:"Químico",
  fisico: "Físico",
  biologico: "Biológico",
  ergonomico_psicossocial:"Ergonómico/Psicossocial", 
  mecanico_acidente: "Mecânico/Acidente", 
  catalogo: "Catálogos",
  codigo: "codigo",
  mod_cadastro_valor_catalogo_req: 'Atenção! Para prosseguir é necessário haver ao menos uma opção adicionada no catálogo. Favor Verificar.',
  msg_catalogo_descricao_unique: 'Já existe uma catalogo com essa descrição cadastrada no sistema. Favor verificar.',
  msg_para_proceguir: 'Atenção! Para prosseguir é necessário Salvar/Cancelar a edição.',
  centro_custo: 'Centro de Custos',
  fontes_geradoras: 'Fontes Geradoras',
  menu:'Menu',
  visao_gravada_sucesso: 'Visão gravada com sucesso',
  deseja_limpar_visao: 'Deseja realmente limpar a visão',
  limpar_visao: 'Limpar visão',
  visao_limpa_sucesso: 'Visão limpa com sucesso',
  relatorio_sendo_gerado: 'Seu relatório está sendo gerado, assim que for finalizado, você receberá uma notificação por e-mail e poderá baixá-lo na janela de consulta relatórios',

  criterio_l: 'Legal',
  criterio_t: 'Técnico',

  tipo_tlv: 'TLV/TWA (Média Ponderada)',
  tipo_stel: 'STEL (Curta Duração)',
  tipo_ceiling: 'Ceiling (Teto)',
  tipo_aren: 'Aren (Aceleração Vibração)',
  tipo_vdvr: 'VDVR (Dose Vibração',

  treinamento: 'Treinamento',

  // ## LABELS COMUNS
  sim: 'Sim',
  nao: 'Não',
  risco: 'Risco',
  informacao: 'Informação',
  
  msg_padrao_excluir_item: 'Deseja realmente excluir este item?',

  risco_categoria_b: 'Biológico',
  risco_categoria_q: 'Químico',
  risco_categoria_f: 'Físico',
  risco_categoria_ma: 'Mecânico/Acidente',
  risco_categoria_ep: 'Ergonômico/Psicossocial',
  risco_categoria_p: 'Periculosidade',

  // ## MODULO CADASTRO > RISCO
  msg_risco_troca_categoria: 'Deseja realmente alterar a categoria? Todos os campos relacionados à categoria no cadastro deste risco poderão ser perdidos.',
  msg_risco_dano_saude_vinculado: 'Dano já vinculado ao risco.',
  msg_risco_selecionar_dano_saude: 'Favor selecionar um Dano à Saúde.',
  msg_risco_dano_saude_excluido: 'Dano à Saúde excluído com sucesso.',
  msg_risco_recomendacao_vinculado: 'Recomendação já vinculada ao risco.',
  msg_risco_selecionar_recomendacao: 'Favor selecionar uma Recomendação.',
  msg_risco_recomendacao_excluido: 'Recomendação excluída com sucesso.',
  msg_risco_propagacao: 'Favor selecionar, ao menos, um tipo de propagação.',
  msg_risco_informa_limite_legal: 'Deve ser informado ao menos um Limite Legal para o Risco.',

  msg_risco_na_maior_lt: 'O valor informado para o NA deve ser inferior ao LT. Favor verificar.',
  msg_risco_limite_legal: 'Aten\u00E7\u00E3o\! J\u00E1 existe um LT Legal cadastrado com esta data de in\u00EDcio. Favor verificar.',
  msg_risco_limite_tecnico: 'J\u00E1 existe um Limite de Crit\u00E9rio T\u00E9cnico marcado como Padr\u00E3o. Favor verificar.',
  msg_risco_clicou_duplicar: 'Favor realizar todas as altera\u00E7\u00F5es necess\u00E1rias e clicar em salvar.',

  // ## MODULO HIGIENE > GHE
  ghe: 'GHE',

  // ## MODULO SSMA > TREINAMENTO
  msg_ssma_treina_conteudo_excluido: 'Conteúdo programático excluído com sucesso. Para que a alteração seja permanentemente salva, clique no botão SALVAR',

  recuperar_senha_sucesso: 'Senha enviada com sucesso.',
  recuperar_senha_erro: 'Usuário não encontrado. Favor verificar.',
  msg_usuario_email_duplicado: 'O e-mail "@email" está vinculado à outro usuário. Favor informar um novo e-mail.',
}