import { LOCALE_ID, Injectable, Inject } from '@angular/core';

import { MENSAGENS_PT_BR } from '../../../../locale/pt-BR';

/**
 * Essa classe não precisa de alterações.
 *
 * Apenas adicione a mensagem no arquivo ts de idiomas na pasta locale na raiz do @apollus.
 *
 * Para usar importe i18nApollus `public i18n: i18nApollus,`
 *
 * Para acessar a mensagem adicona nos arquivos:
 *  `console.log(this.i18n.mensagens.minha_mensagem)`
 */
@Injectable({
  providedIn: 'root'
})
export class i18nApollus {
  mensagens = MENSAGENS_PT_BR;

  locale = {
    'pt-BR': MENSAGENS_PT_BR
  };

  constructor(@Inject(LOCALE_ID) protected localeId: string) {
    this.carregarMensagens();
  }

  carregarMensagens() {
    this.mensagens = this.locale[this.localeId];
  }
}
