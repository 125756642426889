import { Component, OnInit } from '@angular/core';

import { aplsSectionAnimations } from '@apollusepi/common/componentes/section/section-animation';

@Component({
  selector: 'apls-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  animations: [aplsSectionAnimations.fadeInSection]
})
export class SectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
