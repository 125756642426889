import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';

import { CellAcoesComponent } from '@apollusepi/common/componentes/cell-acoes/cell-acoes.component';
import { CellAcoesDirective } from '@apollusepi/common/componentes/cell-acoes/cell-acoes.directive';

@NgModule({
  declarations: [CellAcoesComponent, CellAcoesDirective],
  imports: [OverlayModule],
  exports: [CellAcoesComponent, CellAcoesDirective]
})
export class AplsCellAcoesModule {}
