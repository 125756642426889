/**
 * @license
 * Copyright Apollus EHS Solution Rights Reserved.
 */

import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const aplsPopupAnimations: {
  readonly fadeInContent: AnimationTriggerMetadata;
} = {
  fadeInContent: trigger('fadeInContent', [
    state(
      '*',
      style({
        opacity: 1,
        transform: 'translateY(0)'
      })
    ),
    transition('void => *', [
      style({
        opacity: 0,
        transform: 'translateY(-5px)'
      }),
      animate('0ms 200ms cubic-bezier(0.55, 0, 0.55, 0.2)')
    ]),
    transition('* => void', [
      animate(
        '100ms 200ms linear',
        style({
          opacity: 0,
          transform: 'translateY(-5px)'
        })
      )
    ])
  ])
};
