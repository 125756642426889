import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[InputData]'
})
export class InputDataDirective {
  @Input('InputData') dataFim: Date;
  
  constructor(private control: NgControl) {}

  @HostListener('blur', ['$event'])
  onKeyUp($event: any) {
    let data = new Date($event.target.value.replace(new RegExp(/([0-9]{2})[/]([0-9]{2})[/]([0-9]{4})/), '$2/$1/$3'));
    if (!data.estaValida()) {
      $event.target.value = '';
      if (!!this.control && !!this.control.control) {
        this.control.control.setValue('');
      }
      return;
    }
    
    if (!!this.dataFim && data.comparaDatas(this.dataFim, true, true) == -1) {
      $event.target.value = '';
      if (!!this.control && !!this.control.control) {
        this.control.control.setValue('');
      }
    }
  }

}
