import { Component, Input, TemplateRef, ContentChild, ViewEncapsulation } from '@angular/core';

import { aplsPopupAnimations } from '@apollusepi/common/componentes/popup/popup-animations';

@Component({
  selector: 'apls-popup',
  exportAs: 'aplsPopup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [aplsPopupAnimations.fadeInContent]
})
export class AplsPopupComponent {
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  /**
   * Essa propriedade vai ser utíl para mover a modal entre as posições.
   *
   * Para mover ele acima do elemento de referencia,  você deve passar a quantidade e recuo.
   *
   * Em negativo para ir para cima.
   */
  @Input() offsetY: number = 0;

  /**
   * Essa propriedade define o tamanho da modal.
   */
  @Input() elementWidth: number = 100;

  /**
   * Essa propriedade define se a modal vai abrir direto quando receber o click.
   */
  @Input() autoopen: boolean = true;

  /**
   * Recebe a classe de background
   */
  @Input() background: string = 'cdk-overlay-transparent-backdrop';

  // Propriedade que controla a visualização do popup
  isOpen = false;

  /**
   * Abre a modal sem checagem.
   */
  open() {
    this.isOpen = true;
  }

  /**
   * Como esse component é todo acessado via template, você chama a função de close usando seletor HTML
   */
  openModal() {
    if (this.autoopen) {
      this.isOpen = true;
    }
  }

  /**
   * Como esse component é todo acessado via template, você chama a função de close usando seletor HTML
   */
  close() {
    this.isOpen = false;
  }
}
