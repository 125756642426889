/**********************************************************
 * Criador: Thiago Feijó                                  *
 * Data: 21/12/2017                                       *
 * Descrição: Diretiva para validação do cpf nos campos   *
 * input.                                                 *
 * *******************************************************/

import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[InputCpf]'
})
export class InputCpfDirective {
  constructor(private control: NgControl) {}

  // Faz o teste ao sair do input
  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    if (!this.testacpf($event.target.value)) {
      $event.target.value = '';

      // Tenta limpar o form builder relacionado ao campo
      if (!!this.control && !!this.control.control) {
        this.control.control.setValue('');
      }
    }
  }

  testacpf(strCPF) {
    var numerosIguais = true;
    var soma11 = 0;
    var soma12 = 0;
    var resto11;
    var resto12;
    var validador = /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/;

    // Valida se o cpf está no padrão experado
    if (!validador.test(strCPF)) {
      return false;
    }

    // Remove caracteres da mascara
    strCPF = strCPF.replace(new RegExp('[.]|[-]', 'g'), '');

    // Faz teste se todos numeros não são iguais
    for (let i = 1; i < strCPF.length; i++) {
      if (strCPF[i - 1] != strCPF[i]) {
        numerosIguais = false;
        break;
      }
    }

    // Retorna invalido caso todos numero sejam iguais
    if (numerosIguais) {
      return false;
    }

    for (let i = 1; i <= 10; i++) {
      soma11 = i < 10 ? soma11 + strCPF[i - 1] * (11 - i) : soma11;
      soma12 = soma12 + strCPF[i - 1] * (12 - i);
    }
    // Faz somatorio para o teste do primeiro digito
    resto11 = (soma11 * 10) % 11;
    resto11 = resto11 == 10 || resto11 == 11 ? 0 : resto11;

    // Faz somatorio para o teste do segundo digito
    resto12 = (soma12 * 10) % 11;
    resto12 = resto12 == 10 || resto12 == 11 ? 0 : resto12;

    if (resto11 != strCPF[9]) {
      return false;
    } else if (resto12 != strCPF[10]) {
      return false;
    }

    return true;
  }
}
