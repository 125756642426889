/**********************************************************
 * Criador: Thiago Feijó                                  *
 * *******************************************************/

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { HttpApollus, formatarResponse, UsuarioLogado } from '@apollus-ngx/core';
import { AplsModal } from '@apollus-ngx/orfeu';
import { environment } from '@apollusepi/environments';

@Injectable({
  providedIn: 'root'
})
export class AplsUpload {
  path_raiz;
  mensagemInvalido = 'Permitido somente arquivos nos formatos "{{formatos}}" com até {{tamanho}}MB.';
  tamanho = 3;
  prefixo = '';
  mapArquivos: any = {};
  idfile = '';
  pasta = '';

  constructor(public aplsModal: AplsModal, public http: HttpApollus, public usuarioLogado: UsuarioLogado) {
    this.path_raiz = environment.upload + 'upload.php';
  }

  exec(files: FileList, accept: string) {
    return new Promise((resolve, reject) => {
      let msgInvalido = this.mensagemInvalido.replace(/{{formatos}}/gi, accept.toLocaleLowerCase());
      msgInvalido = msgInvalido.replace(/{{tamanho}}/gi, this.tamanho.toString());
      let accepts = accept
        .toLocaleLowerCase()
        .replace(new RegExp(' .', 'gi'), '.')
        .split(',');

      if (files.length > 0) {
        let f = files[0];
        // Recupera o nome do arquivo sem a extencao
        var nome = f.name.substring(0, f.name.lastIndexOf('.'));

        // Recupera extensao do arquivo. ex: '.jpg', '.png'
        let extensao = this.extraiExtensao(f.name);

        if (!accepts.includes(extensao)) {
          this.aplsModal.toast(msgInvalido, 'A');
          reject();
          return;
        }

        // Verifica se o arquivo selecionado possui mais do que 3MB, emite um alerta
        if (f.size / 1024 / 1024 > this.tamanho) {
          this.aplsModal.toast(msgInvalido, 'A');
          reject();
          return;
        }

        // if(nome.length > 180) {
        //   nome = nome.substring(0, 180);
        // }

        nome = nome + "_" + new Date().getTime() + extensao;

        var reader = new FileReader();
        reader.onload = (function(theFile) {
          return function(e) {};
        })(f);

        reader.readAsDataURL(f);

        var fd = new FormData();
        fd.append('file', f);
        fd.append('novo_nome', nome);

        this.xhrEmPromise(fd, this.callBackEventos.bind(this))
          .then(d => {
            if (d == 200) {
              this.aplsModal.toast('Arquivo enviado com sucesso.', 'S');
              this.mapArquivos[this.idfile + this.pasta + nome] = environment.pasta_tmp + nome;
              resolve(nome);
            }
          })
          .catch(e => {
            if (window.navigator.onLine) {
              this.aplsModal.toast('Um erro ocorreu durante a transferência. Favor carregar novamente o arquivo.',  'E');
            } else {
              this.aplsModal.toast(
                'Você ficou offline durante a transferência. Favor verifique sua conexão e carregue novamente o arquivo.',
                
                'E'
              );
            }
            reject();
          });
      }
    });
  }

  xhrEmPromise(formData, fnEventos) {
    return new Promise(
      function(resolve, reject) {
        let xhr = new XMLHttpRequest();

        xhr.addEventListener('load', () => fnEventos('load'), false);
        xhr.addEventListener('error', err => reject(err), false);
        xhr.addEventListener('progress', err => fnEventos('progress'), false);
        xhr.upload.addEventListener('progress', err => fnEventos('progress'), false);

        xhr.onreadystatechange = function(data) {
          if (xhr.readyState == 4 && xhr.status != 200) {
            reject(xhr.status);
          }

          if (xhr.readyState == 4 && xhr.status == 200) {
            resolve(xhr.status);
          }
        }.bind(this);

        xhr.open('POST', `${this.path_raiz}`, true);
        xhr.send(formData);
      }.bind(this)
    );
  }

  visualiza(arquivo: string, idFile: number | string = this.idfile, pasta = this.pasta) {
    this.downloadLink(arquivo)
      .toPromise()
      .then(() => {
        console.log('Arquivo');
      })
      .catch(err => {
        this.aplsModal.toast('Arquivo não encontrado.', 'E');
      });
  }

  limpaCache() {
    this.mapArquivos = {};
  }

  retornaURL(arquivo: string) {
    let key = this.idfile + this.pasta + arquivo;
    if (!!this.mapArquivos[key]) {
      return this.mapArquivos[key];
    } else {
      return environment.upload + arquivo;
    }
  }

  extraiExtensao(arquivo) {
    return arquivo.substring(arquivo.lastIndexOf('.')).toLowerCase();
  }

  callBackEventos(status) {
    // TODO: recuperar status de update dos campos
  }

  /**
   * Baixa um unico arquivo.
   * return Byte[]
   */
  downloadLink(nomeArquivo) {
    return this.http.get(`${this.path_raiz}?nome=` + nomeArquivo).pipe(map(formatarResponse));
  }

  downloadPastaCopiaPasta(idArquivo, pasta, pastaDestino, subPastaDestino) {
    return this.http.get(`${this.path_raiz}/download_pasta_copia_arquivos/${idArquivo}/${pasta}/${pastaDestino}/${subPastaDestino}`);
  }
}
