import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/finally';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { AplsCookie } from '../cookie';
import { HttpLoader } from './http-loader';
import { UsuarioLogado } from '../usuario-logado';
import { AplsModal } from '@apollus-ngx/orfeu';
import { StorageUtils } from '../utils/storage-utils';

/**
 * Classe manipulada pelo angular para adicionar os interceptors
 */
@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(public httpLoader: HttpLoader, public usuarioLogado: UsuarioLogado, private aplsModal: AplsModal,
              private storageUtils?: StorageUtils) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Adiciona headers se não tiver ainda
    if (!req.headers.get('authorization')) {
      // let header = new HttpHeaders();
      // header = header.append('Accept', 'application/json');
      // header = header.append('Access-Control-Allow-Origin', '*');
      // header = header.append('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT,DELETE');

      let header = new HttpHeaders();
      let token = 'Bearer ' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      header = header.append('Accept', 'application/json');
      header = header.append('Content-Type', 'application/json');
      header = header.append('Access-Control-Allow-Origin', '*');
      header = header.append('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT,DELETE');
      header = header.append('authorization', token);
      
      var authReq = req.clone({
        headers: header
      });
    } else {
      var authReq = req.clone({});
    }

    // Função para loader
    if (this.httpLoader.verifica(req.method + req.url)) {
      this.httpLoader.mostrarLoader(req.method + req.url);
    }

    return next.handle(authReq)
        .do(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.finalizarRequest(req.method + req.url);
          }
        },
        (error: HttpErrorResponse) => {
          this.finalizarRequest(req.method + req.url);

          if (error.status === 401) {
            this.usuarioLogado.logoff();
            document.location.href = document.location.origin;
          }
        }
      )
      .finally(() => {
        this.finalizarRequest(req.method + req.url);
      });
  }

  finalizarRequest(url) {
    this.httpLoader.finalizarLoader(url);
  }
}
