import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'apls-popup-ref',
  exportAs: 'aplsPopupRef',
  templateUrl: './popup-ref.component.html',
  styleUrls: ['./popup-ref.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'popup-ref'
  }
})
export class PopupRefComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
