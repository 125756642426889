import { Component, OnInit, ContentChild, Renderer2, ViewChild, EmbeddedViewRef } from '@angular/core';

import { AplsRow } from '@apollus-ngx/orfeu';
import { CellAcoesDirective } from '@apollusepi/common/componentes/cell-acoes/cell-acoes.directive';

@Component({
  selector: 'apls-cell-acoes',
  template: `<div *aplsCellAcoes></div>`
})
export class CellAcoesComponent implements OnInit {
  @ContentChild(CellAcoesDirective) botoes: CellAcoesDirective;
  @ViewChild(CellAcoesDirective) sombra: CellAcoesDirective;
  viewRef: EmbeddedViewRef<any>;
  viewRefShadow;
  elementAtual;
  mantemAberto = false;

  constructor(public renderer: Renderer2) {}

  ngOnInit() {}

  abre($event: AplsRow, linha) {
    if (this.viewRef) {
      this.fecha();
    }
    this.elementAtual = $event._elementRef.nativeElement;
    this.viewRef = $event._viewContainerRef.createEmbeddedView(this.botoes.template, { $implicit: linha });
    // resolve problema no css nth que pulava linha.
    this.viewRefShadow = $event._viewContainerRef.createEmbeddedView(this.sombra.template);

    this.calculaPosicao();
  }

  fecha() {
    if (this.viewRef && !this.mantemAberto) {
      this.viewRef.destroy();
      this.viewRefShadow.destroy();
      this.renderer.removeClass(this.elementAtual, 'max');
    }
  }

  bloqueiaFechar() {
    this.mantemAberto = true;
  }

  desbloqueiaFechar() {
    this.mantemAberto = false;
  }

  calculaPosicao() {
    var heightOrigem = this.elementAtual.getBoundingClientRect().height;
    this.renderer.addClass(this.elementAtual, 'max');

    for (const item of this.viewRef.rootNodes) {
      this.renderer.setStyle(item, 'top', `-${heightOrigem}px`);
      for (const subitem of item.childNodes) {
        try {
          this.renderer.setStyle(subitem, 'height', `${Math.trunc(heightOrigem)}px`);
        } catch (err) {
          // console.log(err);
        }
      }
    }
  }
}
