import { Directive, HostListener } from '@angular/core';
import { REGEXP_CARACTERES_NAO_ESPECIAIS } from '@apollus-ngx/core';

@Directive({
  selector: '[InputAlfanumerico]'
})
export class InputAlfanumericoDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown($event: any) {
    if (REGEXP_CARACTERES_NAO_ESPECIAIS.test($event.key)) {
      $event.preventDefault();
      return;
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp($event: any) {
    $event.target.value = String($event.target.value).replace(new RegExp(REGEXP_CARACTERES_NAO_ESPECIAIS, 'gi'), '');
  }

}
