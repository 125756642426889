/**********************************************************
 * Criador: Thiago Feijó                                  *
 * Data: 21/12/2017                                       *
 * Descrição: Classe responsavel por gerenciar cookies.   *
 * *******************************************************/

import { EventEmitter, Injectable } from '@angular/core';
import { AplsCache } from '../cache';
import { AplsUnload } from '../unload';
import { StorageUtils } from '../utils/storage-utils';


export interface token {
  token_type: string;
  access_token: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsuarioLogado {
  /**
   * Evento disparado para executar o logoff.
   */
  evtLogoff = new EventEmitter();

  /**
   * Evento disparado ao recalcular o tempo para expirar seção.
   */
  evtTempoSecao = new EventEmitter();

  /**
   * Mantem salvo o interval para checagem de seção.
   */
  private intervalo: any;

  /**
   * Salvar o usuario que está logado.
   */
  usuario: any;

  constructor(public aplsUnload: AplsUnload, private storageUtils: StorageUtils, private aplsCache: AplsCache) {}

  /**
   * Retorna o token do usuario logado.
   */
  get token(): token {
    return this.storageUtils.retornarObjJSON('token');
  }

  _token: token;

  /**
   * Recupera o usuario que está logado.
   */
  recuperaUsuario(): Promise<any> {
    let usuarioLogado = this.storageUtils.retornarObjJSON('usuario_logado')
    return Promise.resolve(usuarioLogado);
  }

  /**
   * Recupera o usuario que está logado.
   */
  retornarUsuarioLogado(): any {
    let usuarioLogado = this.storageUtils.retornarObjJSON('usuario_logado')
    return usuarioLogado;
  }

  /**
   * Método utilizado para retornar um objeto do tipo PerfilModuloUsuario com os perfis que ele tem acesso
   */
  retornarPerfilModuloUsurio(idModulo: number): Promise<any> {
    return Promise.resolve();
  }

  /**
   * Método utilizado para retornar a hierarquia conforme o idioma do usuário logado
   */
  retornarHierarquiaIdioma(): Promise<any> {
    return Promise.resolve();
  }

  /**
   * Inicia interval que realizará a checagem da seção.
   * @param tempo Tempo em milésimos para checagem
   */
  initVerificador(tempo = 1000 * 60) {
    
  }

  /**
   * Função que realiza a checagem da seção do usuário.
   */
  private verificaSecao() {
    
  }

  /**
   * Essa função zera o timer do cookie.
   */
  restart() {
    
  }

  /**
   * Método utilizado para efetuar o processo de autenticação no sistema
   * @param token token do Usuário
   */
  login(usuario: any) {
    this.storageUtils.adicionar('usuario_logado', JSON.stringify(usuario));
  }

  /**
   * Faz limpeza dos cookies.
   */
  logoff() {
    this.aplsUnload.desbloqueia();
    this.storageUtils.deletar('usuario_logado');
  }
}
