import { Component, OnInit, ViewEncapsulation, NgModule } from '@angular/core';

// TODO: Refatorar nome dos arquivos
@Component({
  selector: 'apls-cell-filtro',
  templateUrl: './cell-filtro.html',
  styleUrls: ['./cell-filtro.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'apls-cel-col'
  }
})
export class AplsCellFiltro implements OnInit {
  constructor() {}

  ngOnInit() {}
}

@NgModule({
  declarations: [AplsCellFiltro],
  exports: [AplsCellFiltro]
})
export class AplsCellFiltroModule {}
