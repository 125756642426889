/**********************************************************
 * Criador: Karan Alves Pereira                           *
 * Data: 10/12/2018                                       *
 * *******************************************************/

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LOG, THROW, HttpApollus, UsuarioLogado } from '@apollus-ngx/core';
import { AplsModal } from '@apollus-ngx/orfeu';
import { i18nApollus } from '@apollusepi/common';
import { AppService } from '@apollusepi/app.service';
import { environment } from '@apollusepi/environments';
import { fadeAnimation } from '@apollusepi/common/fadeanimation';

@Component({
  selector: 'app-root',
  template: `
  <main [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
    <router-outlet #o="outlet"></router-outlet>
  </main>`,
  animations: [fadeAnimation]
})
export class AppComponent {
  constructor(
    public http: HttpApollus,
    public aplsModal: AplsModal,
    public i18n: i18nApollus,
    public appService: AppService,
    public router: Router,
    public aplsUsuarioLogado: UsuarioLogado
  ) {
    (<any>window).path = !environment.production ? '/' : '/apollus';

    LOG.subscribe(eventoLog => {
      try {
        eventoLog.url = document.location.href;
        this.appService.gravaLog(eventoLog).subscribe(() => {});
      } catch (err) {
        (<any>console)._log('Erro update log');
      }
    });

    THROW.subscribe(eventoThrow => {
      try {
        try {
          let exception = JSON.parse(eventoThrow.message);
          this.aplsModal.toast(exception.mensagem, exception.titulo, exception.tipoAlerta);
        } catch (error) {
          this.aplsModal.toast(this.i18n.mensagens.msg_erro_interceptor, 'E');
        }

        if (environment.production) {
          let erro = {
            tipoLog: 'throw',
            dataLog: new Date().toISOString(),
            parametros: null,
            stackErro: JSON.stringify(eventoThrow),
            browser: navigator.userAgent,
            url: document.location.href
          };
          this.appService.gravaLog(erro).subscribe(() => {
            (<any>console)._log(this.i18n.mensagens.msg_erro_interceptor);
          });
        }
      } catch (err) {
        (<any>console)._log('Erro update log');
      }
    });
  }
}
