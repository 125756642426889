/**********************************************************************
 * Criador: Karan Alves Pereira                                       *
 * Data: 27/09/2018                                                   *
 * Descrição: Classe responsavel por gerenciar o sub título do home   *
 * ********************************************************************/

import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AplsSubTitle {
  evtSubTitleChange: EventEmitter<string[]> = new EventEmitter();  
  subTitle: string[];

  constructor() {}

  get(): string[] {
    return this.subTitle;
  }

  set(subTitle: string[]): void {
    this.subTitle = subTitle;
    this.evtSubTitleChange.emit(subTitle);
  }
}
