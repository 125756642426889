import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { UsuarioLogado } from '@apollus-ngx/core';
import { AplsModal } from '@apollus-ngx/orfeu';

@Component({
  selector: 'apls-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SideMenuComponent implements OnInit {
  constructor(
    private aplsUsuarioLogado: UsuarioLogado,
    private aplsModal: AplsModal
  ) {}

  ngOnInit() {}

  /**
   * Desloga o usuario.
   */
  logout() {
    // Apresenta mensagem confirmando se será deslogado.
    this.aplsModal.alert({
      texto: 'Deseja realmente sair do sistema?',
      tipo: 'A',
      botoes: [
        {
          color: 'light',
          nome: 'Cancelar'
        },
        {
          nome: 'Sair',
          color: 'primary',
          handler: () => {
            localStorage.setItem('desabilitaProximaChecagemSair', 'disabled');
            this.aplsUsuarioLogado.logoff();
            document.location.href = document.location.origin + "/documentos/#/login";
          }
        }
      ]
    });
  }
}
