/**********************************************************
 * Criador: Thiago Feijó                                  *
 * Data: 21/12/2017                                       *
 * Descrição: Classe responsavel por gerenciar cookies.   *
 * *******************************************************/

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AplsCookie {
  /**
   * Faz leitura dos cookies e retorna o valor a partir da chava
   * @param name chave para encontrar o valor no registro do cookie
   */
  static get(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  /**
   * Seta valor em cookie
   * @param name Chave para identificar cookie
   */
  static set(name, value, options = null) {
    options = options || {};

    var str = encodeURIComponent(name) + '=' + encodeURIComponent(value);
    str += !!options.expires ? '; Path=/;expires=' + options.expires.toUTCString() : ';';

    var cookieLength = str.length + 1;
    if (cookieLength > 4096) {
      console.warn("Cookie '" + name + "' possibly not set or overflowed because it was too large (" + cookieLength + ' > 4096 bytes)!');
    }

    document.cookie = str;
  }

  /**
   * Atualiza time do cooke
   * @param name Chave para identificar cookie
   */
  static atualiza(name, expires) {
    document.cookie = `${name}=${this.get(name)}; Path=/; expires=${expires.toUTCString()}; secure `;
  }

  /**
   * Seta por cima do atual um cookie com data de expiração mais antiga que a data atual
   * assim ele se algo elimina
   * @param name Chave para identificar cookie
   */
  static delete(name) {
    document.cookie = name + '=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
