import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const aplsSectionAnimations: {
  readonly fadeInSection: AnimationTriggerMetadata;
} = {
  fadeInSection: trigger('fadeInSection', [
    state(
      '*',
      style({
        opacity: 1,
        transform: 'translateY(0)'
      })
    ),
    transition('void => *', [
      style({
        opacity: 0,
        transform: 'translateY(5px)'
      }),
      animate('0ms 200ms cubic-bezier(0, 0, 0.55, 0.2)')
    ])
  ])
};
