/**
 * Criador: Thiago Feijó
 * Data: 14/12/2017
 * Descrição: Modulo common importado em todos modulos, que deve ficar disponivel para todos da aplicação deve
 * ficar aqui. Cuidado ao adicionar itens grandes dentro desse modulo. Ele deve conter itens pequenos e de relevancia comum apenas.
 */

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpsRequestInterceptor } from '@apollus-ngx/core';
import { AplsModalModule, AplsTooltipModule, APLS_DATE_LOCALE } from '@apollus-ngx/orfeu';
import { environment } from '@apollusepi/environments';
import {
  InputCpfDirective,
  InputMascaraDirective,
  InputNumericoDirective,
  InputNumericoHifenDirective,
  InputNumericoVirgulaDirective,
  InputAlfanumericoDirective,
  InputDataDirective
} from '@apollusepi/common/diretivas';
import { OrdenaPipe, FiltroPipe } from '@apollusepi/common/pipes';

const IMPORTS_EXPORTS = [CommonModule, ReactiveFormsModule, HttpClientModule, AplsModalModule, AplsTooltipModule];

const DECLARATIONS_EXPORT = [
  InputNumericoDirective,
  InputNumericoHifenDirective,
  InputNumericoVirgulaDirective,
  InputMascaraDirective,
  InputCpfDirective,
  InputAlfanumericoDirective,
  InputDataDirective,
  OrdenaPipe,
  FiltroPipe
];

@NgModule({
  declarations: [...DECLARATIONS_EXPORT],
  imports: [...IMPORTS_EXPORTS],
  exports: [...IMPORTS_EXPORTS, ...DECLARATIONS_EXPORT],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true },
    { provide: APLS_DATE_LOCALE, useValue: environment.getLanguage }
  ]
})
export class AplsCommonModule {}
