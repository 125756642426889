export enum PerfilEnum {
  PERFIL_ADM_MASTER = 'AM',
  PERFIL_ADM_BASICO = 'AB',
  PERFIL_SEGURANCA = 'SG',
  PERFIL_RH = 'RH',
  PERFIL_MEDICO_COORDENADOR = 'MC',
  PERFIL_MEDICO_AVALIADOR = 'MA',
  PERFIL_ADMINISTRATIVO = 'AD',
  PERFIL_ENFERMAGEM = 'EN',
  PERFIL_BASICO = 'BS',
  PERFIL_USUARIO = 'US',
  PERFIL_FONOAUDIOLOGO = 'FO',
  PERFIL_CORPORATIVO = 'CO'
}
