import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SideMenuComponent } from '@apollusepi/common/componentes/side-menu/side-menu.component';
import { AplsTooltipModule } from '@apollus-ngx/orfeu';

@NgModule({
  imports: [CommonModule, AplsTooltipModule],
  declarations: [SideMenuComponent],
  exports: [SideMenuComponent]
})
export class AplsSideMenuModule {}
