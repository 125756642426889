import { Directive, HostListener, Optional, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[InputNumericoVirgula]'
})
export class InputNumericoVirgulaDirective {

  /**
   * por padrao aceita somente duas casas decimais
   */
  @Input()
  totalCasas:number = 2;

  constructor(@Optional() private control: NgControl) { }
  
  /**
   * Pega a tecla no keydown.
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    let campo = (<HTMLInputElement>e.target).value;

    if( (campo.split(',').length == 2 && e.keyCode == 44) || (e.keyCode == 44 && campo.length == 0)){
			return false;
    }
    if(e.keyCode == 188 ){
      return;
    }

    // permite apenas uma virgula no campo
    if(campo.split(',').length == 3 ){
      return false;
    }
    
    if (
      [46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
      // Permite: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Permite: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Permite: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Permite: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Permite: aplicacao, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // Deixe acontecer, não faça nada. Trata no keyup caso tenha forçado a entrada de uma letra.
      return;
    }
    // Certifique-se de que é um número e pare a tecla
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  /**
   * Checa no keyup para validar o se o dado no campo está valido.
   */
  @HostListener('keyup', ['$event'])
  onKeyUp($event: any) {
    let text = $event.target.value;
    text = text.replace(/[^0-9\,]+/g, '');

    text = this.verificarVirgulaCasas(text);
    
    $event.target.value = text;
    
    if (!!this.control && this.control.control) {
      this.control.control.setValue(text);
    }
  }
  
  verificarVirgulaCasas(text) {
    if(text.contem(',') && text.length == 1){
      text = '';
    } else {
      let split:Array<any> = text.split(',');
      
      // permite apenas uma virgula no campo
      if(split.length == 3 ){
        text = text.substring(0, text.length -1);
      }
      else if(split.length == 2){
        if(this.totalCasas > 0 && split[1].length > this.totalCasas){
          text = text.substring(0, text.length -1);
        }
      }
    }

    return text;
  }

  /**
   * Checa no keyup para validar o se o dado no campo está valido.
   */
  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    let text = $event.target.value;
    text = text.replace(/[^0-9\,]+/g, '');

    text = this.verificarVirgulaCasas(text);

    $event.target.value = text;

    if (!!this.control && this.control.control) {
      this.control.control.setValue(text);
    }
  } 
}
