/**
 * Copyright Apollus EHS Solution Rights Reserved.
 */

import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { APP_BASE_HREF, registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeBR from '@angular/common/locales/pt';
import { ToastrModule } from 'ngx-toastr'; // https://github.com/scttcper/ngx-toastr

import { environment } from '@apollusepi/environments';
import { ErrorInterceptor } from '@apollus-ngx/core';
import { AplsCommonModule } from '@apollusepi/common';
import { AppComponent } from '@apollusepi/app.component';
import { AppRoutingModule } from '@apollusepi/app.routing.module';

registerLocaleData(localeBR, environment.getLanguage); // Registra a Locale do Sistema

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, AppRoutingModule, ToastrModule.forRoot(), AplsCommonModule],
  providers: [
    { provide: LOCALE_ID, useValue: environment.getLanguage },
    { provide: APP_BASE_HREF, useValue: environment.base },
    { provide: ErrorHandler, useClass: ErrorInterceptor },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
