import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[aplsCellAcoes]'
})
export class CellAcoesDirective {

  constructor(
    public template: TemplateRef<any>
  ) { }

}
