/**
 * Copyright Apollus EHS Solution Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MensagemAlertaComponent } from '@apollusepi/common/componentes/mensagem-alerta/mensagem-alerta.component';

const EXPORTED_DECLARATIONS = [MensagemAlertaComponent];

@NgModule({
  imports: [CommonModule],
  declarations: EXPORTED_DECLARATIONS,
  exports: EXPORTED_DECLARATIONS
})
export class AplsMensagemAlertaModule {}
