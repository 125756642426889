/********************************************************************
 * Criador: Karan Alves Pereira                                     *
 * Data: 10/12/2018                                                 *
 * Descrição: Lista de Serviços de uso genérico para toda aplicação *
 * ******************************************************************/

import { Injectable } from '@angular/core';

import { HttpApollus } from '@apollus-ngx/core';
import { environment } from '@apollusepi/environments';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  path_raiz = '';
  path_raiz_notificacao = '';

  constructor(public http: HttpApollus) {
    this.path_raiz = environment.endpoint + 'cadastro-server/pessoa';
    this.path_raiz_notificacao = environment.endpoint + 'report-server/relatorio';
  }

  /**
   *  Executa o serviço gravar_visao_filtro (PUT)
   * @param {Object} visao -  {idModulo, id, visao}
   */
  gravarVisaoFiltro(visao) {
    return this.http.put(`${this.path_raiz}/usuario/gravar_visao_filtro`, visao);
  }

  /**
   * Retorna o serviço get_visao_filtro (GET)
   * @param idModulo - Id do módulo para buscar a visão
   */
  getVisaoFiltro(idModulo) {
    return this.http.get(`${this.path_raiz}/usuario/get_visao_filtro/${idModulo}`);
  }

  /**
   * Executa o serviço limpar_Visao_filtro (DELETE)
   * @param id - Id da visão
   */
  limparVisaoFiltro(id) {
    return this.http.delete(`${this.path_raiz}/usuario/limpar_visao_filtro/${id}`);
  }

  /**
   * Metodo utilizado para listar os relatorios do Usuario
   */
  listarRelatoriosUsuario() {
    return this.http.get(`${this.path_raiz_notificacao}/listar_relatorios_usuario`);
  }

  /**
   * Metodo utilizado para deletar uma notificacao a partir do ID
   * @param id ID da Notificacao {@link string}
   */
  excluirRelatorio(id) {
    return this.http.delete(`${this.path_raiz_notificacao}/${id}`);
  }

  /**
   * Metodo utilizado para verificar se o Usuario possui relatorio pendente
   */
  usuarioPossuiRelatorioPendente() {
    return this.http.get(`${this.path_raiz_notificacao}/usuario_possui_relatorio_pendente`);
  }

  gravaLog(log) {
    return this.http.put(`${environment.endpoint}utils-server/utils/adicionar_log`, log);
  }
}
