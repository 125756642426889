/*
 * Criador: Thiago Feijó                                  
 * Data: 21/12/2017                                       
 * Descrição: Diretiva de teste para detectar click fora  
 * de uma div.            
 * 
 * FEATURE: Mudar manipulação de keycode com o numero da tecla direto para o uso de cdk keycode. Esse todo não muda a funcionalidade,
 * é somente para facilitar manutenção.
 * 
 * FEATURE: Aceitar letras na mascara também. Passar Z para letras e 9 para numeros.
 */

import { Directive, HostListener, Input, Optional, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  BACKSPACE,
  TAB,
  SHIFT,
  CONTROL,
  ALT,
  PAUSE,
  PAGE_UP,
  PAGE_DOWN,
  END,
  HOME,
  LEFT_ARROW,
  UP_ARROW,
  RIGHT_ARROW,
  DOWN_ARROW,
  DELETE
} from '@angular/cdk/keycodes';
import { Subscription } from 'rxjs';

/**
 * Nessa diretiva precisamos de uma entrada.
 *
 * Que podem estar em dois tipos:
 *  - string: Passando como 9 caracter(letras e numeros).
 *    Exemplo: <input InputMascara="99999-999">
 *
 *  - Função: Uma função que retorne uma string.
 *    Exemplo:
 *      HTML
 *        <input [InputMascara]="formatoNumerico">
 *      TS
 *        formatoNumerico($event) {
 *          return String($event.target.value).length < 15 ? '(99) 9999-9999' : '(99) 99999-9999';
 *          // Essa função não funcionaria inclusive. Use o metodo formatoNumeroFone no core/utils para fazer isso certo.
 *        }
 */
@Directive({
  selector: '[InputMascara]'
})
export class InputMascaraDirective implements OnInit{
  @Input('InputMascara') mascara: string | Function;

  evtMudanca: Subscription;

  constructor(@Optional() private control: NgControl, public elementRef: ElementRef) {}

  ngOnInit() {
    this.monitoraInput();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown($event: any) {
    if (!this.mascara) {
      return;
    }

    // Não faz nada quando o keycode é o backspace
    if ($event.keyCode === BACKSPACE || $event.keyCode === DELETE || this.tratarTeclar($event)) {
      return;
    }
    // Não faz nada quando o valor já completa a mascara.
    let mascara = retornarMascara(this.mascara, $event);
    if (mascara.length === String($event.target.value).length) {
      $event.preventDefault();
      return;
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp($event: any) {
    let pos = $event.target.selectionStart;
    let tamanho = String($event.target.value).length;
    // Não faz nada quando o keycode é o backspace ou é outra tecla que não tratamos
    if (this.tratarTeclar($event)) {
      return;
    }

    // Retorna valor para o input
    $event.target.value = aplicaMascara(this.mascara, $event);

    this.defineCaret($event, pos, tamanho);
  }

  @HostListener('focus', [])
  onFocus() {
    if (this.evtMudanca) {
      this.evtMudanca.unsubscribe();
      this.evtMudanca = null;
    }
  }

  /**
   * Checa para ver se ao sair do campo o input esta preenchido corretametne
   */
  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    let mascara = retornarMascara(this.mascara, $event);

    this.monitoraInput();

    if (mascara) {
      if (String($event.target.value).length === mascara.length) {
        return;
      }

      // Limpa o input html
      $event.target.value = '';

      // Tenta limpar o form builder relacionado ao campo
      if (!!this.control && this.control.control) {
        this.control.control.setValue('');
      } 
    }
  }

  defineCaret($event, posicao, tamanho) {
    if (posicao == tamanho || posicao > String($event.target.value).length) {
      posicao = String($event.target.value).length;
    }

    $event.target.setSelectionRange(posicao, posicao);
  }

  tratarTeclar($event) {
    var isArrowKey = $event.keyCode == UP_ARROW || $event.keyCode == RIGHT_ARROW || $event.keyCode == DOWN_ARROW || $event.keyCode == LEFT_ARROW;
    var isKeyAction = $event.keyCode == TAB || $event.keyCode == SHIFT || $event.keyCode == CONTROL || $event.keyCode == ALT;
    var isAny =
      $event.keyCode == PAUSE || $event.keyCode == PAGE_UP || $event.keyCode == PAGE_DOWN || $event.keyCode == END || $event.keyCode == HOME;

    return isArrowKey || isKeyAction || isAny;
  }

  monitoraInput() {
    if (this.control.control && !this.evtMudanca) {
      this.evtMudanca = this.control.valueChanges.subscribe(input => {
        let valorInput = this.elementRef.nativeElement.value;
        if (!!input) {
          let mascara = retornarMascara(this.mascara, {
            target: { value:  valorInput},
            keyCode: null,
            type: null
          });

          if (String(valorInput).length !== mascara.length) {
            this.elementRef.nativeElement.value = aplicaMascara(this.mascara, {
              target: { value: input },
              keyCode: null,
              type: null
            });
          }
        }
      });
    }
  }
}

export function aplicaMascara(mask, $event) {
  let mascara = retornarMascara(mask, $event);

  // Pega valor no campo
  var valor = String($event.target.value).replace(/\D/g, '');

  // Formata mascara
  var pad = mascara.replace(/\D/g, '').replace(/9/g, '#');

  // Concatena o valor do campo com a mascara restante.
  var valorMask = valor + pad.substring(0, pad.length - valor.length);

  var valorMaskPos = 0;
  valor = '';
  for (var i = 0; i < mascara.length; i++) {
    if (isNaN(parseInt(mascara.charAt(i)))) {
      // Salva valor do input
      if (($event.keyCode === BACKSPACE || $event.keyCode === DELETE) && valorMask[valorMaskPos] == '#') {
        continue;
      }
      valor += mascara.charAt(i);
    } else {
      // Salva valor da mascara na posição
      valor += valorMask[valorMaskPos++];
    }
  }

  // Remove # que represente o valor 9 da mascara
  if (valor.indexOf('#') > -1) {
    valor = valor.substr(0, valor.indexOf('#'));
  }

  return valor;
}

export function retornarMascara(mascara, $event) {
  if (typeof mascara === 'string') {
    return mascara;
  } else {
    return mascara($event);
  }
}
