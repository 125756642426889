import { Pipe, PipeTransform } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Pipe({
  name: 'filtra'
})
export class FiltroPipe implements PipeTransform {

  transform(items: any[], text: string, field: string): any {
    if (coerceBooleanProperty(text) && !!text.trim()) {
      return items.filter(item => {
        if (coerceBooleanProperty(field)) {
          return item[field].indexOf(text) !== -1
        }
        else {
          return item.indexOf(text) !== -1
        }
      });
    }
    else {
      return items;
    }
  }

}
