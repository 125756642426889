import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SessaoGuard } from '@apollusepi/common/guards/sessao.guard';
import { LogadoGuard } from '@apollusepi/common/guards/logado.guard';

const appRoutes: Routes = [
  {
    path: '',
    //canActivate: [SessaoGuard],
    loadChildren: './paginas/aplicacao/aplicacao.module#AplicacaoModule'
  },
  {
    path: 'login',
    canActivate: [LogadoGuard],
    loadChildren: './paginas/login/login.module#LoginModule'
  },
  {
    path: 'aplicacao',
    // canActivate: [LogadoGuard],
    loadChildren: './paginas/aplicacao/aplicacao.module#AplicacaoModule'
  },
  { path: '', redirectTo: '404', pathMatch: 'full' },
  { path: '404', loadChildren: './paginas/status-404/status-404.module#Status404Module' },
  { path: '**', redirectTo: '404' },
  
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule],
  providers: [SessaoGuard, LogadoGuard]
})
export class AppRoutingModule {}
