import { Pipe, PipeTransform, NgModule } from '@angular/core';

@Pipe({
  name: 'formataData'
})
export class FormataDataPipe implements PipeTransform {
  value: string[] = [];

  transform(value: any, formato: string, args?: any): any {
    if (!value) {
      return '';
    }

    if (typeof value === "string" && value.indexOf('Z') == -1) {
      value = value + 'T12:00:00.000Z';
    }

    let formatoPadrao = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    };

    switch (formato) {
      case 'data':
        delete formatoPadrao.hour;
        delete formatoPadrao.minute;
        delete formatoPadrao.second;
        delete formatoPadrao.hour12;
        break;
      case 'hora':
        delete formatoPadrao.year;
        delete formatoPadrao.month;
        delete formatoPadrao.day;
        break;
    }

    return Intl.DateTimeFormat((<any>window).idioma, formatoPadrao).format(new Date(value))
  }
}

@NgModule({
  declarations: [FormataDataPipe],
  exports: [FormataDataPipe]
})
export class FormataDataPipeModule {}
