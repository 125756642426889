import {
  Directive,
  Input,
  DoCheck,
  ElementRef,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[InputStatus]',
  exportAs: 'InputStatus',
  host: {
    class: 'div-status',
    '[class.div-green]': 'valor == "A"',
    '[class.div-red]': 'valor == "I"'
  }
})
export class InputStatusDirective implements DoCheck {
  @Input('InputStatus') valor: string;

  constructor(public elementRef: ElementRef, private renderer: Renderer2) {}

  ngDoCheck() {
    let el = window
      .getComputedStyle(this.elementRef.nativeElement)
      .getPropertyValue('font-size');
    var margem = Number(el.replace('px', ''));
    margem = (margem - 9) / 2;
    margem = margem > 0 ? margem : 0;

    this.renderer.setStyle(this.elementRef.nativeElement, 'margin', `${margem}px auto ${margem}px auto`);    
  }
}
