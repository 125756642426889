import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'apls-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class AplsPaginatorComponent {
  // Guarda a pagina atual
  page = 0;

  // Guarda o numero total de paginas
  lastpage = 0;

  /**
   * Retorna mudança no paginador.
   */
  @Output() change = new EventEmitter();

  @Input() registro: any;

  constructor() {}

  calculaPaginador() {
    this.lastpage = this.registro.totalPaginas > 0 ? Math.ceil(this.registro.totalPaginas / this.registro.maxResultados) : 0;
    return this.lastpage;
  }

  /**
   * Reinicia o contador.
   */
  restart() {
    this.registro.pagina = 0;
    this.change.emit({ page: this.registro.pagina });
  }

  /**
   * Função chamada pelo botão de retorno
   */
  btnBack() {
    if (this.registro.pagina > 1) {
      this.change.emit({ page: --this.registro.pagina });
    }
  }

  /**
   * Função chamada pelo botão de avançar.
   */
  btnNext() {
    if (this.registro.pagina < this.lastpage) {
      this.change.emit({ page: ++this.registro.pagina });
    }
  }
}
